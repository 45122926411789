<template>
    <v-card>
        <vue-html2pdf :show-layout="false"
                      :float-layout="true"
                      :enable-download="true"
                      :preview-modal="true"
                      :paginate-elements-by-height="1400"
                      margin="20"
                      filename="Codigo Guarda"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      pdf-orientation="landscape"
                      pdf-content-width="1120px"
                      @progress="onProgress($event)"
                      @hasStartedGeneration="hasStartedGeneration()"
                      @hasGenerated="hasGenerated($event)"
                      ref="html2Pdf">

            <section slot="pdf-content">
                <div id="printArea">
                    <div>
                        <div class="logo">
                            <img src="./tf3040.jpg" alt="Logo">
                        </div>
                        <div id="codigo">
                            <b>{{codigo()}}</b>
                        </div>
                        <div class="grilla">
                            <b class="item item-1">VEHICULO</b>
                            <p class="item item-2">{{marcaModelo()}}</p>
                            <b class="item item-3">FECHA</b>
                            <p class="item item-4">{{item.fechaSiniestro}}</p>
                            <b class="item item-5">DOMINIO</b>
                            <p class="item item-6">{{item.nombre}}</p>
                            <b class="item item-7">MOTOR</b>
                            <p class="item item-8">{{item.motor}}</p>
                            <b class="item item-9">CHASIS</b>
                            <p class="item item-10">{{item.chasis}}</p>
                            <b class="item item-11">COMPAÑÍA</b>
                            <p class="item item-12">{{item.cliente.nombre}}</p>
                            <b class="item item-13">SINIESTRO</b>
                            <p class="item item-14">{{item.siniestro}}</p>
                            <b class="item item-15">DEPOSITO</b>
                            <p class="item item-16">{{item.deposito.nombre}}</p>
                        </div>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
        <v-chip @click="print"
                class="ma-1"
                color="yellow">
            Imprime
        </v-chip>
    </v-card>
</template>
<style>
    #codigo {
        margin: 0 auto; /* Centrar el contenido */
        text-align: center;
        height: 200px;
    }

        #codigo b {
            font-size: 20rem; /* Ajustar el tamaño de fuente según sea necesario */
            height: auto; /* Ajustar la altura según el contenido */
        }

    .logo {
        text-align: center;
        height: 180px;
    }

        .logo img {
            max-width: 100%;
            height: auto;
        }

    .grilla {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(20px, auto); /* Ajustar la altura de las filas */
        gap: 10px;
    }

    .item, .itemc {
        border: 1px solid #ccc;
        padding: 15px;
        text-align: center;
        font-size: 18px;
        color: #333;
    }

    /* Definir las áreas específicas en la cuadrícula */
    .item-1 {
        grid-column: span 1;
    }

    .item-2 {
        grid-column: span 3;
    }

    .item-15 {
        grid-column: span 1;
    }

    .item-16 {
        grid-column: span 3;
    }
</style>

<script>
    import VueHtml2pdf from 'vue-html2pdf'  // https://www.npmjs.com/package/vue-html2pdf
    export default {
        props: ['item'],
        data() {
            return {
                output: null
            }
        },
        methods: {
            print() {
                this.$refs.html2Pdf.generatePdf()
                //// Pass the element id here
                //this.$htmlToPaper('printArea');
            },
            marcaModelo() {
                if (this.item.modeloValor != null &&
                    this.item.modeloValor != undefined &&
                    this.item.modeloValor.modeloValorId != '' &&
                    this.item.modeloValor.modeloValorId != null &&
                    this.item.modeloValor.modeloValorId != this.item.cliente.abrv + this.item.codigo
                ) {
                    return this.item.modeloValor.marca + ' ' + this.item.modeloValor.modelo + ' ' + this.item.modeloValor.tipo;
                } else {
                    if (this.item.marca.nombre != '' && this.item.modelo.nombre != '') {
                        return this.item.marca.nombre + ' ' + this.item.modelo.nombre;
                    } else {
                        return 'Vehiculo';
                    }
                }
            },

            codigo() {
                return this.item.cliente.abrv + this.fmtnumber(this.item.codigo, 3);
            },

            fmtnumber(s, len) {
                var sret = "";
                for (var j = 0; j < len; j++)
                    sret += "0";

                sret += s;
                sret = sret.substring(sret.length - len);
                return sret;
            }

        },
        components: {
            VueHtml2pdf
        }
    }
</script>
